<template>
  <div class="recruitDetails">
    <TopNavBar2 />
    <div class="box">
      <el-breadcrumb separator=">>">
        <el-breadcrumb-item :to="{ path: '/mobile/recruit' }"
          >招聘</el-breadcrumb-item
        >
        <el-breadcrumb-item>招聘详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="recruit_table">
        <div class="title_box">
          <div>销售经理</div>
          <el-button type="text">投递简历</el-button>
        </div>
        <div class="info_box">
          <div :key="item.key" class="item" v-for="item in list">
            {{ item.title }}:<span>{{ data[item.key] }}</span>
          </div>
        </div>
      </div>
      <div class="describe_box">
        <div>
          <h3>职位描述：</h3>
          <p>{{ data.jobDescription }}</p>
        </div>
        <div>
          <h3>岗位要求：</h3>
          <p v-for="(item, index) in data.jobRequirements" :key="index">
            {{ index + 1 + "." + item }}
          </p>
        </div>
      </div>
    </div>
    <Footer2 />
  </div>
</template>

<script>
export default {
  name: "RecruitDetails",
  data() {
    return {
      data: {
        name: "多媒体策划",
        price: "5-6K",
        experience: "1-3年",
        education: "不限",
        number: "1人",
        workLocation: "台州市",
        releaseTime: "2022-08-12",
        jobDescription: "电子政务/集成类销售；大客户销售;",
        jobRequirements: [
          "大专及以上学历。",
          "热爱销售工作。",
          "具备良好的市场分析能力、判断能力及独立开拓市场的能力，有良好的客户服务意识。",
          "注重团队合作，具备良好的人际沟通能力、分析解决问题能力。",
        ],
      },
      list: [
        {
          title: "工作经验",
          key: "experience",
        },
        {
          title: "学历要求",
          key: "education",
        },
        {
          title: "薪酬范围",
          key: "education",
        },
        {
          title: "工作地点",
          key: "education",
        },
        {
          title: "招聘人数",
          key: "number",
        },
        {
          title: "发布时间",
          key: "releaseTime",
        },
      ],
    };
  },
  created() {
    this.data.dates = this.data.date.split("-");
  },
};
</script>

<style scoped lang="scss">
.recruitDetails {
  .box {
    padding: 15px 16px 30px;
    ::v-deep .el-breadcrumb {
      .el-breadcrumb__item {
        .el-breadcrumb__inner,
        .el-breadcrumb__separator {
          font-size: 14px;
          font-weight: 200;
          line-height: 30px;
          color: #333;
        }
        .el-breadcrumb__separator {
          font-family: "AlibabaPuHuiTi-Light";
          margin: 0 8px;
        }
      }
    }
    .recruit_table {
      margin-top: 15px;
      width: 100%;
      border-top: 1px solid #e4e4e4;
      border-left: 1px solid #e4e4e4;
      .title_box {
        height: 50px;
        background-color: #f5f5f5;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
        border-right: 1px solid #e4e4e4;
        border-bottom: 1px solid #e4e4e4;
        div {
          font-family: "AlibabaPuHuiTi-Regular";
          font-weight: 400;
          font-size: 15px;
          color: #000000;
        }
        .el-button {
          font-size: 14px;
          color: #006eff;
        }
      }
      .info_box {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .item {
          width: 50%;
          line-height: 44px;
          font-size: 14px;
          border-right: 1px solid #e4e4e4;
          border-bottom: 1px solid #e4e4e4;
          text-align: left;
          font-family: "AlibabaPuHuiTi-Light";
          font-weight: 200;
          color: #000000;
          box-sizing: border-box;
          padding-left: 15px;
          span {
            font-weight: 200;
            font-family: "AlibabaPuHuiTi-Light";
            margin-left: 8px;
          }
        }
      }
    }
    .describe_box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 5px;
      > div {
        h3 {
          font-weight: 400;
          font-size: 15px;
          color: #333333;
          text-align: left;
          line-height: 45px;
          font-family: "AlibabaPuHuiTi-Regular";
        }
        p {
          font-weight: 200;
          font-size: 14px;
          color: #666666;
          line-height: 28px;
          text-align: left;
          font-family: "AlibabaPuHuiTi-Light";
          letter-spacing: 1px;
        }
      }
      > div + div {
        margin-top: 20px;
      }
    }
  }
}
</style>
